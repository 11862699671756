import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import laptopImg from "../../Assets/about.png";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              <strong className="purple">Tego</strong>
            </h1>
            <p>
            Tego is an innovative decentralized project dedicated to advancing research, treatments, and potential cures for thyroid disease. By harnessing the power of decentralized technologies, Tego aims to create a collaborative ecosystem where researchers, medical professionals, and patients can actively participate in the quest for solutions to thyroid-related health challenges. Through decentralized funding mechanisms, transparent data sharing, and open-access publishing, Tego empowers stakeholders to contribute their expertise and resources towards accelerating the development of new therapies and improving patient outcomes in the fight against thyroid disease.
            </p>
            
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>
        <h1 className="project-heading">
          Direct Drug <strong className="purple">Delivery </strong>
        </h1>
        <p>
        We are interested in direct drug delivery device development and research for thyroid disease.
        </p>

        

        <h1 className="project-heading">
          <strong className="purple">Combination</strong> Therapy
        </h1>

        <p>
        We are researching and supporting combination therapy using T3 and T4 for patients with hypothyroidism.
        </p>

      </Container>
    </Container>
  );
}

export default About;
