import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import TeamCard1 from "./TeamCards1";
import TeamCard2 from "./TeamCards2";
import TeamCard3 from "./TeamCards3";
import Particle from "../Particle";
import img1 from "../../Assets/Team/img1.png";
import img2 from "../../Assets/Team/img2.png";
import img3 from "../../Assets/Team/img3.png";



function Team() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1></h1>
      <h1 className="project-heading">
          Our <strong className="purple">Team </strong>
        </h1>
        <p style={{ color: "white" }}>
          Meet our team.
        </p>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>


          <Col md={4} className="project-card">
            <TeamCard3
              imgPath={img2}
              isBlog={false}
              title="Brian Haney"
              description="Brian Haney is the Founder of Tego. Brian is a lawyer in private practice with expertise in intellectual property and securities law. He was inspired to start Tego to work on curing hypothyroidism, a disease he was born with." 
              ghLink1 = "https://scholar.google.com/citations?user=6_XhYkQAAAAJ&hl=en" 
              ghLink2 = "https://github.com/Bhaney44"           
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Team;
