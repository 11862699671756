import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/homeLogo.png";
import Tilt from "react-parallax-tilt";


function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Tego's <span className="purple"> Purpose </span> 
            </h1>
            <p>
            Tego's purpose is to revolutionize the landscape of thyroid research and development through the power of decentralized networks. By embracing decentralization, Tego aims to democratize access to resources and expertise, thus fostering a collaborative ecosystem where researchers, medical professionals, and patients can collectively drive innovation forward. Tego's mission is to expand opportunities for exploration and discovery in the field of thyroid disease, ultimately striving to accelerate the development of new treatments and cures. Through transparent and inclusive processes, Tego seeks to empower stakeholders to contribute their unique perspectives and insights, leading to breakthroughs that can significantly improve the lives of individuals affected by thyroid conditions around the globe.
            </p>

          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>

        </Row>
        
      </Container>
    </Container>
  );
}
export default Home2;
