import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Particle from "../Particle";
import Home2 from "./Home2";

import Type from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <h1></h1>
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">

              <h1 className="heading-name">

                <strong className="main-name"> TEGO</strong>
              </h1>
              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col>
              <h2>
               <strong className="main-name">Mission</strong>
              </h2>
              <p>
              Tego's mission is to spearhead research and development efforts aimed at discovering novel cures and treatments for thyroid disease. Through decentralized collaboration and innovation, Tego is committed to leveraging cutting-edge technologies and interdisciplinary expertise to address the complexities of thyroid-related health issues. By fostering an inclusive and transparent ecosystem, Tego aims to accelerate the pace of discovery, ultimately improving the quality of life for individuals affected by thyroid disease worldwide.
              </p>
            </Col>
          </Row>



        </Container>
      </Container>
      <Home2 />
      
    </section>
  );
}

export default Home;
