import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCards0 from "./ProjectCards0";
import ProjectCards1 from "./ProjectCards1";
import ProjectCards3 from "./ProjectCards3";
import ProjectCards4 from "./ProjectCards4";
import ProjectCards5 from "./ProjectCards5";
import Particle from "../Particle";
import img1 from "../../Assets/Projects/img1.png";
import img3 from "../../Assets/Projects/img3.png";
import img4 from "../../Assets/Projects/img4.png";
import img5 from "../../Assets/Projects/img5.png";
import img6 from "../../Assets/Projects/img6.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Our Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects we are working on.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCards1
              imgPath={img1}
              isBlog={false}
              title="Direct Drug Delivery"
              description="Thyroid direct drug delivery devices aim to administer thyroid hormones directly into the bloodstream, bypassing the gastrointestinal tract for rapid absorption and precise dosing. These injectable systems offer alternative treatment options for patients."
              ghLink="https://patents.google.com/patent/US20220281938A1/en"
              ghLink1="https://patents.google.com/patent/US20230347050A1/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCards0
              imgPath={img4}
              isBlog={false}
              title="Thyroid IP"
              description="Thyroid intellectual property typically involves patents related to novel methods, compounds, or technologies used in diagnosing, treating, or monitoring thyroid disorders. These patents can cover innovations in hormone replacement therapies, diagnostic assays, or medical devices aimed at improving patient outcomes in thyroid health management."
              ghLink="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4366405"
              ghLink1="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3654804"
            />
          </Col>



          <Col md={4} className="project-card">
            <ProjectCards5
              imgPath={img3}
              isBlog={false}
              title="Thyroid Physiological Modeling"
              description="Thyroid physiology is often modeled mathematically to understand hormone regulation and its impact on various bodily functions. Physiological modeling of the thyroid gland aids in predicting hormone dynamics, guiding treatment strategies, and elucidating complex interactions within the endocrine system."
              ghLink="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4818616"           
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCards3
              imgPath={img5}
              isBlog={false}
              title="T3-T4 Combination Therapy"
              description="T3-T4 combination therapy involves administering both triiodothyronine (T3) and thyroxine (T4) hormones to mimic the natural balance found in the human body. This approach is used in some cases of hypothyroidism where patients do not respond adequately to T4-only therapy, aiming to alleviate symptoms and optimize thyroid hormone levels."
              ghLink="https://medium.com/@tegotech/thyroid-hormones-thyroxine-and-triiodothyronine-af104d48ae93"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCards4
              imgPath={img6}
              isBlog={false}
              title="Thyroid and Neurocognition"
              description="Thyroid function plays a crucial role in neurocognitive processes, with both hypo- and hyperthyroidism affecting cognitive function. Imbalances in thyroid hormones can lead to symptoms such as memory impairment, attention deficits, and mood disturbances, highlighting the intricate relationship between thyroid health and neurocognition."
              ghLink=""
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
